// comment styles originally from
// https://github.com/travisdowns/travisdowns.github.io/blob/96170d361260b0bf99b6feb7defd82fb85d97b79/_sass/comment-styles.scss
$comment-font-size: $base-font-size !default;
$small-font-size: 14px !default;

//
// Staticman new comment form
//
.comments, .contact-form {

    fieldset {
        border: none;
        padding: 0;
    }

    // each staticman text field is wrapped in this div
    .textfield {
        max-width: 100%;
        padding: 0.5rem 0;
    }

    // fields except the comment field get this class too
    // to keep them a reasonable size
    .narrowfield {
        width: 420px;
    }

    .hp {
        display: none;
    }

    textarea {
        vertical-align: top;
    }

    .textfield__input {
        border: 1px solid rgba(0,0,0,.12);
        padding: 4px 5px;
        width: 100%;
        font-size: $comment-font-size;
    }
    .notify-me, .g-recaptcha {
        padding: 0.5rem 0;
    }
    .button {
        background: rgba(158,158,158,.2);
        box-shadow: 0 2px 2px 2px rgba(0,0,0,.14);
        border: none;
        border-radius: 2px;
        padding: 4px 8px;
        overflow: hidden;
        cursor: pointer;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        margin: 4px 0px 0px 0px;
    }

    //
    // Staticman existing comments
    //
    .comment__new {
        padding: 0px 0px 14px 0px;
    }
    .comment__admin_tag {
        font-size: 0.8em;
        border-style: solid;
        border-width: 1px;
        background-color: ld-select(#bfb, #050);
        border-radius: 2em;
        padding: 3px 4px 2px 4px;
        vertical-align: middle;
    }
    .comment__date {
        font-size: $small-font-size;
    }
    .comment__body {
        font-size: $comment-font-size;
    }
    .comment__meta {
        text-align: left;
        font-size: $small-font-size;
    }
    .comments__existing {
        padding: 8px 0px 0px 0px;
    }
    .comment {
        padding: 0px 0px 0px 0px;
        margin-bottom: 1em;
    }
    .comment.child {
        margin-left: 3em;
    }
    .comment__reply-link {
        font-size: $small-font-size;
        cursor: pointer;
    }

}

//
// Pop-up modal window for Staticman comments
//
.show-modal {
    overflow: hidden;
    position: relative;
}
.show-modal:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(#fff, 0.5);
}

.show-modal .modal {
    display: block;
}

.modal {
    display: none;
    position: fixed;
    width: 330px;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -150px;
    min-height: 0;
    z-index: 9999;
    box-shadow: 2px 2px 2px 2px rgba(#000,.5);
    padding: 16px;
    border-radius: 2px;
    background: #fff;
    overflow: hidden;
    box-sizing: border-box;
}

.mdl-card__supporting-text {
    max-height: 400px;
    overflow: auto;
    padding: 16px;
}

.modal-title {
    margin-bottom: 0px;
}

.disabled {
    input,
    button,
    textarea,
    label {
        pointer-events: none;
        cursor: not-allowed;
        filter: alpha(opacity=65);
        box-shadow: none;
        opacity: 0.65;
    }
}
